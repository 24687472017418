.root {
  max-width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  order: 2;
  &::-webkit-scrollbar{
    /* width: 7px;
    background-color: #e1e1e1;
    height: 4px!important; */
    display:none;
  }
  @media (min-width: 1052px) {

    margin-left: auto;
    margin-right: auto !important;
  }
 
}

.serviceNames {
  display: flex;
  overflow-x: auto;
  /* width: 100%;
  justify-content: center; */
  @media(max-width:991px){
    justify-content:space-between;
  }
  @media(max-width:767px){
    padding-bottom:30px;
  }
  &::-webkit-scrollbar{
    width: 7px;
    background-color: #e1e1e1;
    height: 4px!important;
    cursor: pointer;
    border-radius:5px;
  }
  &::-webkit-scrollbar-thumb{
    background-color: #BDBDBD;
    border-radius:5px;
  }
}
.serviceName {
  margin-right: 25px;
  &:last-child {
    margin-right: 0px;
  }
  & button {
    border: 0;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--colorBlack);
    cursor: pointer;
    @media(max-width:991px){
      padding:0 !important;
    }
  }
  & svg {
    width: 24px !important;
    height: 24px !important;
  }

  & .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family:var(--fontFamilyFactor) !important;
    margin-top:5px;
  }
}
.selectedOption{
  & > button{
    & > span{
      color:#000 !important;
    }
  }
}
/* .all {
  & svg rect {
    fill: var(--colorGrey300);
  }
} */
/* .all.selectedOption {
  & svg rect {
    fill: var(--colorBlack);
  }
} */
.all,
.other,
.training,
.yoga,
.pilates,
.strength,
.wellbeing,
.powerlifting,
.event {
  & svg path {
    fill: var(--colorGrey300);
  }
  & span{
  color:#BDBDBD !important;
   
  }
}

.all.selectedOption,
.other.selectedOption,
.training.selectedOption,
.yoga.selectedOption,
.pilates.selectedOption,
.strength.selectedOption,
.wellbeing.selectedOption,
.powerlifting.selectedOption,
.event.selectedOption {
  & svg path {
    fill: var(--colorBlack);
  }
}

.pilates.selectedOption {
  & svg path {
    stroke: var(--colorBlack);
  }
}
