@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportSmall) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.listingCard {
  width: 100%;
  & > div:nth-child(1){
    border-radius:10px !important;
    & > div{
      padding-bottom:100% !important;
      & > div{
        display:flex;
        justify-content: center;
        align-items: center;
        & > video{
          object-fit: contain ;
        }
        & > img{
          /* object-position:top !important; */
          object-fit:contain ;
        }
        & > div{
          bottom: 10px !important;
          padding:8px 12px !important;
          min-height: 111px !important;
          /* background-color: rgba(0, 0, 0, 0.2); */
          border-radius: 8px;
          width: calc(100% - 16px);
          & > span:nth-child(1){
            font-size:32px !important;
            line-height:35px !important;
            font-weight:500;
            font-family:var(--fontFamilyFactor) !important;
          }
          & > span:nth-child(2){
            font-size:14px;
            line-height:20px;
            font-weight:500;
            font-family:var(--fontFamilyFactor) !important;
            max-height:200px;
            line-clamp:3;
            -webkit-line-clamp:3;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
  & > div:nth-child(2){
    & .listingPrice{
      font-family:var(--fontFamilyHelvetica);
      font-weight:400;
      font-size:24px;
      line-height:29px;
    }
    & > div:nth-child(2),& > div:nth-child(3), & > div:nth-child(4), & > div:nth-child(5){
      font-size: 12px !important;
      font-weight:500 !important;
      font-family:var(--fontFamilyHelvetica) !important;
      /* line-height:14px !important; */
    }
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
  margin-bottom:50px;
}
