.root {
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  justify-content:space-between;
  @media(max-width:767px){
    padding-top: 25px;
  }
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  /* margin-right: 8px; */
  font-size:14px;
  line-height:18px;
  color:#000000;
  font-family:var(--fontFamilyFactor);
  font-weight:400;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
}

.sortyByWrapper {
  display: inline-flex;
  /* margin-left: auto; */
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}
.srchHeader{
  @media(max-width:767px){
    /* border-bottom: 0.5px solid #bdbdbd; */
    padding-bottom: 4px;
  }

  & > h2{
    font-size:32px;
    line-height:35px;
    color:#000000;
    font-weight:500;
    font-family:var(--fontFamilyFactor) !important;
    @media(max-width:767px){
      font-size: 25px;
      margin-top: 0;
    }
  }
}
.searchDiv {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  & > svg {
    & > path {
      fill: #000;
    }
  }
  & > input {
    border: none;
    background: none;
    /* font-size: 12px; */
    font-family: 'Factor A';
    font-weight: 500;
    color: #000;
    padding: 12px 12px !important;
    &:focus {
      box-shadow: none;
      &::placeholder {
        color: transparent !important;
      }
    }
    &::placeholder {
      color: #000;
    }
  }
  @media(min-width:767px){
    display:none !important;
  }
}