@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  /* background-color: var(--colorWhite); */

  /* shadows */
  /* box-shadow: var(--boxShadow); */

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  /* Layout */
  display: flex;
  /* width: 66px; */
  width: 40px;

  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  /* padding: 15px 24px; */
  padding: 15px 0px;

  position: relative;

  @media (--viewportMedium) {
    padding: 21px 0px;
  }
}
.searchMenu {
  padding: 12px 24px;

  @media (--viewportMedium) {
    padding: 18px 24px;
  }
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  /* position: absolute;
  top: 5px;
  right: 5px; */

  /* Style: red dot with white border */
  margin-bottom: 2px;
  background-color: var(--colorFail);
  border: 2px solid var(--colorWhite);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);

  @media (--viewportMedium) {
    /* top: 23px; */
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: #fff;
  margin-left: auto;
}
.rootSearchIcon {
  stroke: var(--colorGrey700);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--colorGrey300);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: max(100%, 100vh);
    height: auto;
    padding: 24px;
    background-color: var(--colorWhite);
    border-bottom: none;
  }
}
.modalContainerSearchForm {
  composes: modalContainer;
  padding: 0;
  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
  @media (max-width: 767px) {
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    height: 100vh !important;
    left: 0 !important;
    right: 0 !important;
  }
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.container {
  background: none;
  background-color: transparent;
  padding: 20px 25px;
  position: fixed;
  left: 0;
  width: 100%;
  right: 0;
  top:0 !important;
  transform: translateY(70%);
  @media (max-width: 767px) {
    transform: translateY(80%);
    padding: 20px 15px;
  }
  & > a {
    & > img {
      width: 60px;
      height: 20px;
      @media (max-width: 991px) {
        width: 65px;
        height: 25px;
      }
    }
  }

  & > div:nth-child(2) {
    display: flex !important;
    margin-left: auto !important;
    align-items: center !important;
  }
}
.joinSignIn {
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  /* height: 26px; */
  border-radius: 40px;
  /* padding: 0 15px; */
  padding: 8px 20px;
  border-radius: 40px;
  /* min-width: 116px; */
  width: max-content;
  font-family: 'Factor A';
}
.menuDiv {
  position: absolute;
  text-align: left;
  background-color: #fcfcfc;
  color: #6f6f6f;
  width: 198px;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border-radius: 10px;
  right: 62px;
  margin-top: 220px;
  border: 0.2px solid #bdbdbd;
  box-shadow: 0 2px 10px 0px #0000000d;
  gap: 8px;
  & .linkText {
    font-size: 14px;
    color: #6f6f6f;
    font-weight: 400;
    &:hover {
      text-decoration: none;
    }
  }
  /* & .linkText:nth-child(1) {
    margin-bottom: 5px;
  } */
}
.logoutLinkText {
  font-size: 14px;
  color: #6f6f6f;
  font-weight: 400;
  color: #ff0000;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.customNavbarscrolled {
  background-color: #fff;
  & .searchDiv {
    display: flex;
    align-items: center;
    & > svg {
      & > path {
        fill: #000;
      }
    }
    & > input {
      border: none;
      background: none;
      &:focus {
        box-shadow: none;
      }
    }
  }

  & .listingLinkText {
    color: #000;
  }
  & .listingLinkText {
    padding: 0 50px;
  }
  & .joinSignIn {
    color: #fff;
    background-color: #000;
  }
  .rootMenuIcon {
    fill: #000;
    /* margin-left: auto; */
  }
}
.mobileMenuDiv {
  width: 100%;
  position: absolute;
  text-align: left;
  background-color: #fcfcfc;
  color: #6f6f6f;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  /* border-radius: 10px; */
  right: 0.2px;
  margin-top: 294px;
  /* border: 0.2px solid #bdbdbd; */
  box-shadow: 0 2px 3px 0px #0000000d;
  /* gap: 8px; */
  & .linkText {
    font-size: 14px;
    color: #6f6f6f;
    font-weight: 400;
    &:hover {
      text-decoration: none;
    }
  }
}
.closeButton {
  /* composes: h5 from global; */
  background-color: none !important;
  cursor: pointer;
  font-size: 15px;
}
.firstMobileDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}
.searchDiv {
  display: flex;
  align-items: center;
  border-top: 1px solid #e4e4e4;
  & > svg {
    & > path {
      fill: #000;
    }
  }
  & > input {
    border: none;
    background: none;
    font-size: 12px;
    font-family: 'Factor A';
    font-weight: 500;
    color: #000;
    padding: 12px 12px !important;
    &:focus {
      box-shadow: none;
      &::placeholder {
        color: transparent !important;
      }
    }
    &::placeholder {
      color: #000;
    }
  }
}
.priorityLinkLabel {
  color: #000;
  font-family: 'Factor A';
  font-size: 12px;
  font-weight: 500;
}
.priorityLinkDiv {
  padding: 10px 0px;
  border-top: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > a {
    /* text-decoration: none; */
    color: #000;
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    & > span {
      color: #000;
      font-family: 'Factor A';
      font-size: 12px;
      font-weight: 500;
    }
  }
  & svg {
    fill: none;
    margin-left: auto;
    width: 10px !important;
  }
}
.topbarJoinLabel {
  color: #fff;
}
.SignText {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  &:hover {
    text-decoration: none;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  /* box-shadow: inset 0 0 20px 20px #23232329; */
}
.notificationParentDiv {
  display: flex;
  align-items: center;
  gap: 2px;
  & > span {
    color: #000;
    font-family: 'Factor A';
    font-size: 12px;
    font-weight: 500;
  }
}
.signLogInDiv {
  display: flex;
  gap: 10px;
}
.signLogIn {
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding: 8px 15px;
  border-radius: 40px;
  /* min-width: 116px; */
  width: 100px !important;
  font-family: 'Factor A';
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobileMenuDivNew {
  margin-top: 200px;
}
.mobileauthenticateDiv {
  margin-top: 472px;
  /* padding-top:500px; */
}
.mobileTraineeMenu{
  margin-top: 383px;
}