.fieldDateInput {
  box-shadow: var(--boxShadowPopup);
  background-color: #fff;
  position: absolute;
  z-index: 9;

  & :global(.SingleDatePicker_picker__directionLeft) {
    background-color: #fff !important;
  }
  & :global(.DayPicker__horizontal) {
    background-color: #fff !important;
  }
  & :global(.DateInput_input) {
    padding-left: 12px !important;
    padding-right: 38px !important;
  }

  & :global(.CalendarMonth_caption),
  & :global(.DayPicker_weekHeader),
  & :global(.DayPickerNavigation_button) {
    color: #000 !important;
  }

  & :global(.DayPicker_weekHeader) {
    top: 50px !important;
  }

  & :global(.CalendarDay) {
    color: #000 !important;
    background-color: transparent !important;
    border-width: 0 !important;
    cursor: pointer;

    &:hover {
      background-color: var(--colorGrey100) !important;
      color: var(--colorGrey700) !important;
    }
  }

  & :global(.renderedDay) {
    color: #000 !important;
  }

  & :global(.CalendarDay__blocked_calendar) {
    color: #4a4a4f !important;
    opacity: 0.5 !important;
    text-decoration: line-through !important;
    cursor: auto !important;
    background-color: transparent !important;
    &:hover {
      background-color: transparent !important;
    }
  }

  & :global(.CalendarDay__selected) {
    background-color: var(--marketplaceColor) !important;
    color: #fff !important;
    topborder-radius: 0px !important;
    color: #fff !important;

    & :hover {
      color: #fff !important;
    }
  }

  & :global(.CalendarDay__today) {
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23000' fill-rule='evenodd'/></svg>") !important;
    background-position: center 28px !important;
    background-size: 14px 2px;
    background-repeat: no-repeat;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%234a4a4f' fill-rule='evenodd'/></svg>") !important;
    }
  }

  & :global(.CalendarDay__selected.CalendarDay__today) {
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>") !important;
    background-position: center 28px !important;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 11px;
    position: absolute;
    cursor: pointer;
    display: inline;
    &:first-of-type {
      left: 24px;
    }
    &:last-of-type {
      right: 24px;
    }

    @media (--viewportMedium) {
      top: 18px;
    }
  }
  & :global(.DayPickerNavigation_button) {
    color: var(--colorGrey700);
    border: 0;
  }
  & :global(.DayPickerKeyboardShortcuts_show) {
    display: none;
  }

  & :global(.DayPicker__withBorder) {
    box-shadow: none !important;
    border-radius: 0 !important;
  }
}

.fieldDateInputWrapper {
  position: absolute;
  /* top: 0; */
}

.divider {
  margin-top: 48px;
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  /* stroke: var(--colorWhite);
  fill: var(--colorWhite); */

  stroke: currentColor;
  fill: currentColor;
}

.calenderBtn {
  margin-top: -20px;
  z-index: 2;
  position: relative;
  margin-left: 8px;
  margin-bottom: 8px;

  & .clearBtn {
    border: 0;
    outline: none;
    cursor: pointer;
    color: var(--colorGrey300);
    font-size: 14px;
    line-height: 16px;
    padding: 12px;

    &:hover,
    &:focus {
      color: var(--colorGrey700);
    }

    & * {
      pointer-events: none;
    }
  }
}

.dateBtn {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #d8dce6;
  padding: 10px 8px;
  width: 100%;
  text-align: left;
  outline: none;
  border-radius: 6px;
  background-position: top 16px right 12px !important;
  cursor: pointer;
  background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.5L6 5.5L11 0.5" stroke="black"/></svg>') !important;
  font-family: var(--fontFamilyFactor) !important;
  background-size: 10px;
  &:focus {
    border-color: #80bdff;
  }
}

.placeholderDateBtn {
  color: #b9b5c5;
}

.fieldSelect {
  /* flex-basis: 50%;
  max-width: 50%; */
  align-self: stretch;
  color: var(--colorBlack);
  & > label {
    font-family: var(--fontFamilyFactor) !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-bottom: 10px;
  }
  & select {
    cursor: pointer;
    padding: 6px 8px !important;
    margin-top: 5px;
    font-family: var(--fontFamilyFactor);
    font-weight: 500;
    font-size: 16px;
    /* line-height:22px; */
    @media (max-width: 767px) {
      padding: 10px 8px !important;
    }
    box-shadow: none !important;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.5L6 5.5L11 0.5" stroke="black"/></svg>') !important;
    background-position: center right 10px;
  }
  @media(max-width:767px){
    flex-basis: 100%;
  max-width: 100%;
  }
}

.datesWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  /* & > * {
    flex-basis: 20%;
    max-width: 20%;
    margin-right: 16px;

    &:last-child {
      margin-right: 0px;
    }

    &:nth-child(3) {
      max-width: 12px;
      flex-basis: 12px;
    }
  } */

  & .date {
    flex-basis: 146px;
    max-width: 156px;
    margin-right: 10px;
    margin-bottom: 16px;
    @media (max-width: 991px) {
      flex-basis: 180px;
      max-width: 180px;
    }
    @media(max-width:767px){
      max-width:100%;
      flex-basis:100%;
    }
  }
}

.lineBetween {
  align-self: center;
  margin-top: 24px;
  margin-right: 8px;
  margin-left: 8px;
}

.form {
  width: 320px;
  /* width: 40%;
  flex: 0 0 40%; */
  /* @media(max-width:1199px){
    width: 50%;
    flex: 0 0 50%;
  } */
   @media(max-width:1199px){
    width: 400px;
   }
   @media(max-width:991px){
    width: 100%;
   }
}

.selectWrapper {
  /* display: flex; */
  width: 150px;
  margin-bottom: 16px;
  @media(max-width:991px){
    width: 300px;
  }
  @media(max-width:767px){
    width: 100%;
  }
}
.dateBtnTitle {
  color: var(--colorBlack);
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  font-family: var(--fontFamilyFactor);
  margin-bottom: 10px;
}
